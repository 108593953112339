import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';  // Use HTML5 backend
import Analytics from './Analytics';


import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import ModeSelectionPage from './pages/ModeSelectionPage';
import DeathScene from "./components/deathScene"
import DollSelectionPage from './pages/DollSelectionPage';
// Dolls
import ElvenHunterDoll from './pages/dolls/ElvenHunterDoll';
import CyberHackerDoll from './pages/dolls/CyberHackerDoll';
import DarkMageDoll from './pages/dolls/DarkMageDoll';
import PhoenixFighterDoll from './pages/dolls/PhoenixFighterDoll';
//Story
import StorySelectionPage from './pages/StorySelectionPage';
import BattleTemplate from './pages/BattleTemplate'
import ElvenHunterStoryScene01 from './pages/ElvenHunter/ElvenHunterStoryScene01';
import ElvenHunterBattle01 from './pages/ElvenHunter/ElvenHunterBattle01';
import ElvenHunterPass01 from './pages/ElvenHunter/ElvenHunterPass01';
import ElvenHunterChase01 from './pages/ElvenHunter/ElvenHunterChase01';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
import UpgradePage from './pages/upgradePage'; // Add this import
import fetchUserData from './utils/fetchUserData'; // Assume you have this utility for fetching user data
import './App.css';



const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>  {/* Use the HTML5 backend for drag-and-drop */}
      <Router>
        <Analytics /> {/* Add Google Analytics */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/mode-selection" element={<ModeSelectionPage />} />
          <Route path="/upgrade" element={<UpgradePage />} />
          <Route path="/dashboard" element={<Dashboard />} />

          {/* Minimum Pledge $1 - Use component instead of JSX */}
          <Route path="/select-doll" element={<ProtectedRoute minimumPledge={1}>
              <DollSelectionPage fetchUserData={fetchUserData} />
            </ProtectedRoute>} />
          <Route path="/doll/elvenhunter" element={<ProtectedRoute minimumPledge={1}><ElvenHunterDoll /></ProtectedRoute>} />
          <Route path="/doll/cyberhacker" element={<ProtectedRoute minimumPledge={1}><CyberHackerDoll /></ProtectedRoute>} />
          <Route path="/doll/darkmage" element={<ProtectedRoute minimumPledge={1}><DarkMageDoll /></ProtectedRoute>} />
          <Route path="/doll/phoenixfighter" element={<ProtectedRoute minimumPledge={1}><PhoenixFighterDoll /></ProtectedRoute>} />


          <Route path="/story-selection" element={<StorySelectionPage />} />
          <Route path="/story/elvenhunter/scene01" element={<ElvenHunterStoryScene01 />} />
          <Route path="/story/elvenhunter/battle01" element={<ElvenHunterBattle01 />} />
          <Route path="/story/elvenhunter/pass01" element={<ElvenHunterPass01 />} />
          <Route path="/story/elvenhunter/chase01" element={<ElvenHunterChase01 />} />
        </Routes>
      </Router>
    </DndProvider>
  );
};

export default App;
