import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import elvenHunterThumbnail from '../assets/images/dolls/ElvenHunter/ElvenHunter-thumbnail.png';
import cyberHackerThumbnail from '../assets/images/dolls/CyberHacker/CyberHacker-thumbnail.png';
import darkMageThumbnail from '../assets/images/dolls/DarkMage/DarkMage-thumbnail.png';
import phoenixFighterThumbnail from '../assets/images/dolls/PhoenixFighter/PhoenixFighter-thumbnail.png';
import watermark from '../assets/images/AI-Watermark.png';
import '../App.css';

const DollSelectionPage = ({ fetchUserData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await fetchUserData(); // Fetch user data
        console.log('Fetched user data:', data); // Log the fetched data

        // Check if pledge data exists and is sufficient
        if (!data || !data.pledgeData || data.pledgeData.currentlyEntitledDollars < 1) {
          console.log('Insufficient pledge, redirecting to upgrade...');
          navigate('/upgrade'); // Redirect to upgrade page if the pledge is too low
        } else {
          setLoading(false); // Stop loading if pledge is sufficient
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to load user data.');
        setLoading(false);
      }
    };

    loadUserData();
  }, [navigate, fetchUserData]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const handleSelectElvenHunter = () => {
    navigate('/doll/elvenhunter');
  };

  const handleSelectCyberHacker = () => {
    navigate('/doll/cyberhacker');
  };

  const handleSelectDarkMage = () => {
    navigate('/doll/darkmage');
  };

  const handleSelectPhoenixFighter = () => {
    navigate('/doll/phoenixfighter');
  };

  return (
    <div className="doll-selection-page">
      <h1>Select Your Doll</h1>

      <div className="doll-grid">
        <div className="doll-item" onClick={handleSelectElvenHunter}>
          <img src={elvenHunterThumbnail} alt="Elven Hunter" className="thumbnail" />
          <p>Elven Hunter</p>
        </div>

        <div className="doll-item" onClick={handleSelectCyberHacker}>
          <img src={cyberHackerThumbnail} alt="Cyber Hacker" className="thumbnail" />
          <p>Cyber Hacker</p>
        </div>

        <div className="doll-item" onClick={handleSelectDarkMage}>
          <img src={darkMageThumbnail} alt="Dark Mage" className="thumbnail" />
          <p>Dark Mage</p>
        </div>

        <div className="doll-item" onClick={handleSelectPhoenixFighter}>
          <img src={phoenixFighterThumbnail} alt="Phoenix Fighter" className="thumbnail" />
          <p>Phoenix Fighter</p>
        </div>
      </div>

      <div className="patreon-support">
        <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
          <img src={watermark} alt="Support me on Patreon" className="watermark" />
        </a>
        <p className="patreon-text">
          <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
            Support me on Patreon by clicking here
          </a>
        </p>
      </div>
    </div>
  );
};

export default DollSelectionPage;
