// src/pages/UpgradePage.js
import React from 'react';
import { Link } from 'react-router-dom';

const UpgradePage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Upgrade Your Membership</h1>
      <p>You need to pledge more to access this content. Consider upgrading your Patreon membership to unlock this feature.</p>
      <a href="https://www.patreon.com/yourcampaign" target="_blank" rel="noopener noreferrer">
        <button style={{ backgroundColor: '#f96854', color: '#fff', padding: '10px 20px', marginTop: '20px' }}>Upgrade on Patreon</button>
      </a>
      <br />
      <Link to="/dashboard" style={{ marginTop: '20px', display: 'block', textDecoration: 'none', color: '#007bff' }}>Go Back to Dashboard</Link>
    </div>
  );
};

export default UpgradePage;
