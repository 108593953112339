import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const HomePage = () => {
  const navigate = useNavigate();

  // Updated handleLogin function
  const handleLogin = () => {
    window.location.href = 'https://api.ai-pinups.io/auth/patreon';
  };
  

  const handleDisagree = () => {
    window.location.href = 'https://www.google.com';
  };

  return (
    <div className="home-page">
      <h1>Welcome to the Game</h1>
      <p>You must be logged in to access the game.</p>
      <div className="button-group">
        <button onClick={handleLogin} style={{ backgroundColor: '#f96854', color: '#fff' }}>
          Login with Patreon
        </button>
        <button onClick={handleDisagree} style={{ backgroundColor: '#ccc', color: '#000', marginLeft: '10px' }}>
          Disagree
        </button>
      </div>
    </div>
  );
};

export default HomePage;
