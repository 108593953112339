import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DiceRoller from '../components/DiceRoller.jsx';
import DialogueContainer from '../components/DialogueContainer.js';
import ChoicesContainer from '../components/ChoicesContainer.js';
import HPComponent from '../components/HPComponent.jsx';

function BattleTemplate() {
    const navigate = useNavigate();

    const [state, setState] = useState({
        sceneStage: 'intro', // Start at intro
        narrative: "You encounter a fearsome opponent. Battle is inevitable!",
        battleOutcome: '',
        playerHP: 3, // Player starts with 3 HP
        characterArmor: 3, // Enemy starts with 3 armor
        initiativeWinner: '',
    });

    const [playerHP, setPlayerHP] = useState(3); // Set initial HP to 3

    const [diceRollerDisabled, setDiceRollerDisabled] = useState(false);

    const handleReplay = () => {
        setPlayerHP(3); // Reset HP to the initial value
        navigate('/battleTemplate'); // Navigate to the battle template
    };


    // Handle initiative rolls
    const handleInitiativeRoll = (playerRoll) => {
        const characterRoll = Math.floor(Math.random() * 20) + 1;
        let outcomeText = '';
        if (playerRoll >= characterRoll) {
            outcomeText = `You rolled ${playerRoll}, and your opponent rolled ${characterRoll}. You go first!`;
            setState(prevState => ({
                ...prevState,
                battleOutcome: outcomeText,
                initiativeWinner: 'player',
            }));
        } else {
            outcomeText = `You rolled ${playerRoll}, and your opponent rolled ${characterRoll}. They go first!`;
            setState(prevState => ({
                ...prevState,
                battleOutcome: outcomeText,
                initiativeWinner: 'character',
            }));
        }
    };

    // Player attacks
    const handlePlayerAttack = (dc, successText, failText) => {
        const roll = Math.floor(Math.random() * 20) + 1;
        let outcomeText = '';

        if (roll >= dc) {
            outcomeText = successText;
            setState(prevState => ({
                ...prevState,
                characterArmor: prevState.characterArmor - 1, // Reduce armor on success
            }));
        } else {
            outcomeText = failText;
        }

        if (roll < dc) {
            setPlayerHP(prevHP => prevHP - 1);
        }


        setState(prevState => ({
            ...prevState,
            battleOutcome: outcomeText,
            sceneStage: 'outcome', // Go to outcome stage
        }));
    };

    // Character attacks
    const handleCharacterAttack = () => {
        const dc = 14; // Set DC for the character's attack
        const roll = Math.floor(Math.random() * 20) + 1;
        let outcomeText = '';

        if (roll >= dc) {
            outcomeText = 'The opponent lands a blow!';
            setState(prevState => ({
                ...prevState,
                playerHP: prevState.playerHP - 1, // Reduce player HP on success
            }));
        } else {
            outcomeText = 'You dodge the attack!';
        }

        setState(prevState => ({
            ...prevState,
            battleOutcome: outcomeText,
            sceneStage: 'outcome',
        }));
    };

    // Next turn handler
    const nextTurn = () => {
        if (state.characterArmor <= 0) {
            navigate('/victoryScene'); // Navigate to victory on character defeat
        } else if (state.playerHP <= 0) {
            navigate('/deathScene'); // Navigate to defeat on player HP 0
        } else {
            if (state.initiativeWinner === 'player') {
                setState(prevState => ({ ...prevState, sceneStage: 'playerAttack' }));
            } else {
                setState(prevState => ({ ...prevState, sceneStage: 'characterAttack' }));
            }
        }
    };

    return (
        <div className="battle-template">
            {state.sceneStage === 'intro' && (
                <DialogueContainer text={state.narrative}>
                    <button
                        onClick={() => setState(prevState => ({ ...prevState, sceneStage: 'initiative' }))}
                        className="next-button"
                    >
                        Next
                    </button>
                </DialogueContainer>
            )}

            {state.sceneStage === 'initiative' && (
                <DialogueContainer text="Roll for initiative!">
                    <DiceRoller onRoll={handleInitiativeRoll} disabled={diceRollerDisabled} />
                    {state.battleOutcome && (
                        <>
                            <p className="result-text">{state.battleOutcome}</p>
                            <button
                                onClick={nextTurn}
                                className="next-button"
                            >
                                Continue
                            </button>
                        </>
                    )}
                </DialogueContainer>
            )}

            {state.sceneStage === 'playerAttack' && (
                <DialogueContainer text="Choose your attack!">
                    <ChoicesContainer
                        choices={[
                            { text: 'Overhead Strike', action: () => handlePlayerAttack(14, 'You land a strong hit!', 'Your attack misses!') },
                            { text: 'Resourceful Attack', action: () => handlePlayerAttack(16, 'You catch them off guard!', 'They deflect your strike!') },
                            { text: 'Goofy Maneuver', action: () => handlePlayerAttack(17, 'Your antics work, you hit them!', 'Your move backfires.') },
                        ]}
                    />
                </DialogueContainer>
            )}

            {state.sceneStage === 'characterAttack' && (
                <DialogueContainer text="Your opponent attacks!">
                    <button
                        onClick={handleCharacterAttack}
                        className="next-button"
                    >
                        Continue
                    </button>
                </DialogueContainer>
            )}

            {state.sceneStage === 'outcome' && (
                <DialogueContainer text={state.battleOutcome}>
                    <button onClick={nextTurn} className="next-button">
                        Continue
                    </button>
                    <p>Player HP: {playerHP}</p>
                </DialogueContainer>
            )}

            <HPComponent
                maxHP={3} // Set the max HP
                loseHP={state.playerHP} // Pass the player's current HP
                onDeath={() => navigate('/deathScene')} // Ensure onDeath navigates to the death scene
            />

        </div>
    );
}

export default BattleTemplate;
